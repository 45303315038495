export default {
  BUTTON_PRIMARY_CLASS: 'button button--primary cm_button-primary',
  BUTTON_SECONDARY_CLASS: 'button cm_button-secondary',
  LOAD_MORE_BUTTON_CLASS: 'button button--primary',

  FACET_BUTTON_CLASS: '',
  MAX_PRICE: 'Max',
  MIN_PRICE: 'Min',

  FACET_BAR_WHEELS_TIRES_CONTAINER_CLASS: 'facetholder',
  FACET_BAR_WHEELS_TIRES_TITLE_CLASS: 'facettitle',
  FACET_BAR_WHEELS_TIRES_BODY_CLASS: 'facetbody',

  SEARCH_BOX_FORM_CLASS: 'search-bar',
  SEARCH_BOX_INPUT_CLASS: 'searchField',
  SEARCH_BOX_BUTTON_CLASS: 'button-search',
  SEARCH_BOX_PLACEHOLDER: "{this.name === 'HomeSearchBox_tab' ? 'Search By VIN' : 'Search our store...'}",

  SELECT_YOUR_VEHICLE_BLOCK: 'Select Your Vehicle',

  GARAGE_TITLE: '',
  GARAGE_SIZE: '<span class="garage-size countPill countPill--positive" key="garage-size">{{size}}</span>',

  DISCARD_VEHICLE: 'Clear',
  CHANGE_VEHICLE: 'Change',
};
