
        import { createElement as _createElement } from 'react';
import { Fragment as _Fragment } from 'react';
import _map from 'lodash-es/map';
export default function SearchBoxDialogRT () {
    return _createElement('div', { 'className': 'cm_search-box-root__dialog' }, _createElement('div', { 'className': 'clear-self cm_search-box_container' }, _createElement('div', { 'className': 'dialog-container' }, _createElement('div', {
        'className': 'dialog-content',
        'onSubmit': this.preventFormSubmission
    }, _createElement('div', { 'className': 'dialog-header' }, _createElement('div', { 'className': 'dialog-title' }, 'Search'), _createElement('div', {
        'className': 'cm_search-box-dialog_close-button close',
        'onClick': this.close
    }, _createElement('svg', {
        'className': 'cm_icon cm_icon-times',
        'height': '20px',
        'role': 'img',
        'viewBox': '0 0 22 22'
    }, _createElement('path', { 'd': 'M1,1L21,21M21,1L1,21' })))), _createElement('div', { 'className': 'cm_search-box_form-container search-bar' }, [
        [this.searchInput(function () {
                return _createElement('div', { 'className': 'searchField cm_searchInput' }, this.name === 'HomeSearchBox_tab' ? 'Search By VIN' : 'Search our store...');
            }, {
                widgetName: 'undefined',
                items: undefined
            })],
        _createElement('span', {
            'className': 'cm_search-box_clear-container',
            'key': '5912'
        }, this.inputNotEmpty ? _createElement('span', {
            'className': 'cm_search-box_clear',
            'onClick': this.clearInput,
            'key': '979'
        }, '\n    ', window.innerWidth > 768 ? '\u2715' : 'Clear', '\n  ') : null),
        _createElement('button', {
            'className': 'cm_search-box_submit button-search',
            'title': 'Search',
            'aria-label': 'search button',
            'data-cm-role': 'add-query',
            'key': '5914'
        }, [_createElement('svg', {
                'viewBox': '0 0 1024 1024',
                'className': 'icon icon-search',
                'id': 'icon-search',
                'key': '12840'
            }, _createElement('path', {
                'className': 'path1',
                'd': 'M966.070 981.101l-304.302-331.965c68.573-71.754 106.232-165.549 106.232-265.136 0-102.57-39.942-199-112.47-271.53s-168.96-112.47-271.53-112.47-199 39.942-271.53 112.47-112.47 168.96-112.47 271.53 39.942 199.002 112.47 271.53 168.96 112.47 271.53 112.47c88.362 0 172.152-29.667 240.043-84.248l304.285 331.947c5.050 5.507 11.954 8.301 18.878 8.301 6.179 0 12.378-2.226 17.293-6.728 10.421-9.555 11.126-25.749 1.571-36.171zM51.2 384c0-183.506 149.294-332.8 332.8-332.8s332.8 149.294 332.8 332.8-149.294 332.8-332.8 332.8-332.8-149.294-332.8-332.8z'
            }))])
    ]), [this.dropdown(function () {
            function repeatSections1(sections, sectionsIndex) {
                return [sections(function () {
                        function repeatItems1(items, itemsIndex) {
                            return [items(function () {
                                    function mergeProps_text(inline, external) {
                                        var res = Object.assign({}, inline, external);
                                        if (inline.hasOwnProperty('style')) {
                                            res.style = _.defaults(res.style, inline.style);
                                        }
                                        if (inline.hasOwnProperty('className') && external.hasOwnProperty('className')) {
                                            res.className = external.className + ' ' + inline.className;
                                        }
                                        return res;
                                    }
                                    function mergeProps_product(inline, external) {
                                        var res = Object.assign({}, inline, external);
                                        if (inline.hasOwnProperty('style')) {
                                            res.style = _.defaults(res.style, inline.style);
                                        }
                                        if (inline.hasOwnProperty('className') && external.hasOwnProperty('className')) {
                                            res.className = external.className + ' ' + inline.className;
                                        }
                                        return res;
                                    }
                                    function onError1_facetValue(e) {
                                        e.target.classList.add('cm_hide');
                                    }
                                    function mergeProps_facetValue(inline, external) {
                                        var res = Object.assign({}, inline, external);
                                        if (inline.hasOwnProperty('style')) {
                                            res.style = _.defaults(res.style, inline.style);
                                        }
                                        if (inline.hasOwnProperty('className') && external.hasOwnProperty('className')) {
                                            res.className = external.className + ' ' + inline.className;
                                        }
                                        return res;
                                    }
                                    return this.template === 'text' ? _createElement('div', mergeProps_text({ 'className': 'cmTemplate_text' }, { dangerouslySetInnerHTML: { __html: this.textHighlight } })) : this.template === 'product' ? _createElement('div', { 'className': 'cmTemplate_product' }, _createElement('div', { 'className': 'image-block' }, _createElement('img', {
                                        'src': this.imageOrDefault(this.images_image_thumbnail1 || this.image_thumbnail || this.images_image_standard1 || this.image_standard),
                                        'alt': 'Image of ' + (this.removeHTML(this.sku) || this.removeHTML(this.mpn) || this.removeHTML(this.name)),
                                        'onError': this.onImageError
                                    })), _createElement('div', { 'className': 'description-block' }, _createElement('div', mergeProps_product({ 'className': 'title' }, { dangerouslySetInnerHTML: { __html: this.name } })), _createElement('div', { 'className': 'price' }, '\n    ', this.price_varies ? `From: ${ this.formatPrice(this.price) }` : this.formatPrice(this.price), '\n  '))) : this.template === 'facetValue' ? _createElement('div', { 'className': 'cmTemplate_facetValue' }, this.imageUrl ? _createElement('div', {
                                        'className': 'image-block',
                                        'key': '35'
                                    }, _createElement('img', {
                                        'src': this.imageUrl,
                                        'alt': 'Image of ' + this.facetValue,
                                        'onError': onError1_facetValue.bind(this)
                                    })) : null, _createElement('div', { 'className': 'description-block' }, _createElement('div', mergeProps_facetValue({ 'className': 'title' }, { dangerouslySetInnerHTML: { __html: this.value } })), this.hitCount ? _createElement('span', {
                                        'className': 'hit-count',
                                        'key': '335'
                                    }, '(', this.hitCount, ')') : null)) : _createElement('div', { 'className': 'cmTemplate_Unknow' }, JSON.stringify(this));
                                }, { count: undefined })];
                        }
                        return _createElement('div', { 'className': 'section' + (this.sectionTitle ? ` ${ this.sectionTitle }` : '') }, this.sectionTitle ? _createElement('div', {
                            'className': 'section-title-div',
                            'key': '94'
                        }, _createElement('div', { 'className': 'item section-title' }, _createElement('b', {}, this.totalHits > 1 ? `${ this.totalHits } ` : '', this.sectionTitle), !!this.vehicleString ? ` for ${ this.vehicleString }` : '')) : null, _createElement.apply(this, [
                            'div',
                            { 'className': 'cmRepeater_items' },
                            _map(this.items, repeatItems1.bind(this))
                        ]));
                    }, { count: undefined })];
            }
            return _createElement('div', { 'className': 'dialog-body cm_autocomplete cm_dropdown' }, _createElement.apply(this, [
                'div',
                { 'className': 'cmRepeater_sections' },
                _map(this.sections, repeatSections1.bind(this))
            ]), this.browseAllButton ? [[this.browseAllButton(function () {
                        return _createElement('a', { 'className': 'item button-item cm_browseAllButton' }, 'Browse all ', this.totalHits, ' products');
                    }, {
                        widgetName: 'undefined',
                        items: undefined
                    })]] : null);
        }, {
            widgetName: 'undefined',
            items: undefined
        })]))));
}
        export const componentNames = ["cm:searchInput","cm:browseAllButton","cm:dropdown"]