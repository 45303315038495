//convermax workaround for lack of css module import info experiments.css
var css = "\nhead{--webpack-convermax-client-ui-288:_1332,_6096,_1786,_9656,_9848,_5572,_4410,_3412;}"
var style = document.createElement('style');
style.appendChild(document.createTextNode(css));
document.head.appendChild(style);
try{
        if(!getComputedStyle(document.head).getPropertyValue('--webpack-convermax-client-ui-288')) {
          document.head.style.setProperty('--webpack-convermax-client-ui-288', "_1332,_6096,_1786,_9656,_9848,_5572,_4410,_3412");
        }
      }catch (ex) {
        console.error(ex);
      }